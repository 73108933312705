import '@hotwired/turbo-rails'
Turbo.session.drive = false
// jquery is not included directly here because it is provided by the ProvidePlugin in webpack.config.js
// => https://github.com/activeadmin/activeadmin/issues/7376#issuecomment-1653746141
require('jquery-ui/ui/widgets/datepicker')
require('jquery-ui/ui/widgets/dialog')
require('jquery-ui/ui/widgets/sortable')
require('jquery-ui/ui/widgets/tabs')
require('jquery-ui')
require('jquery-ujs')
require('@activeadmin/activeadmin')

require('../stylesheets/active_admin.scss')
require('@fortawesome/fontawesome-free/js/all.js')
require('@fortawesome/fontawesome-free/css/all.css')

require('arctic_admin')

require('react-datepicker/dist/react-datepicker.css')

// for interactive_select via choices.js
import Choices from 'choices.js'
require('choices.js/public/assets/styles/choices.css')

// Stimulus controllers
import 'controllers'

import hljs from 'highlight.js/lib/core'
import json from 'highlight.js/lib/languages/json'
import 'highlight.js/styles/github.css'
hljs.registerLanguage('json', json)

// Support component names relative to this directory:
var componentRequireContext = require.context('components/admin', true)
// var ReactRailsUJS = require('react_ujs')
// // eslint-disable-next-line react-hooks/rules-of-hooks
// ReactRailsUJS.useContext(componentRequireContext)

const ReactRailsUJS = require('react_ujs')
ReactRailsUJS.useContext(componentRequireContext)

// Prevent double mount on page load
ReactRailsUJS._firstTurboLoadSkipped = false
ReactRailsUJS.handleEvent('turbo:frame-load', () => {
  if (ReactRailsUJS._firstTurboLoadSkipped) ReactRailsUJS.handleMount()
  ReactRailsUJS._firstTurboLoadSkipped = true
})
ReactRailsUJS.handleEvent('turbo:load', () => {
  if (ReactRailsUJS._firstTurboLoadSkipped) ReactRailsUJS.handleMount()
  ReactRailsUJS._firstTurboLoadSkipped = true
})

// Unmount components and call cleanup functions after Turbo navigations
ReactRailsUJS.handleEvent('turbo:before-render', (e) => {
  ReactRailsUJS.unmountComponents(e.target)
})

// for interactive_select via choices.js
const initializeChoices = () => {
  console.log('initialisiere interactive select')
  const choicesInputs = document.querySelectorAll('[data-choices-input]')
  choicesInputs.forEach((input) => {
    // eslint-disable-next-line
    new Choices(input, {
      itemSelectText: '',
      searchResultLimit: 10,
      searchFields: ['label'],
      searchPlaceholderValue: 'Bitte auswählen oder tippen um zu suchen.',
      removeItems: true, // remove items that do not match
      fuseOptions: {
        // disable fuzzy matching using "threshold: 0.0"
        threshold: 0.0,
        // with "ignoreLocation: true" the position of the match does not matter
        // => works like "contains" in ransack when true
        ignoreLocation: true,
      },
    })
  })
}
document.addEventListener('turbo:frame-load', initializeChoices)
document.addEventListener('turbo:load', initializeChoices)

// code highlighting for json configs etc.
document.addEventListener('turbo:load', (event) => {
  document.querySelectorAll('pre code').forEach((block) => {
    hljs.highlightBlock(block)
  })
})

// Add tenant switcher
document.addEventListener('turbo:load', (event) => {
  const tabsElement = document.getElementById('utility_nav')
  if (tabsElement) {
    // Fetch the dropdown partial using Fetch API
    fetch('/admin/tenants_dropdown', {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        return response.text()
      })
      .then((html) => {
        // Insert the fetched HTML as the first child of the tabs element
        tabsElement.insertAdjacentHTML('afterbegin', html)
        const tenantFormElement = document.getElementById('tenant-select')
        if (tenantFormElement) {
          tenantFormElement.addEventListener('change', () =>
            document.getElementById('tenant-form').submit()
          )
        }
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error)
      })
  } else {
    console.error('Element with id "tabs" not found.')
  }
})


document.addEventListener('turbo:load', (event) => {
  console.log('PACK active_admin.js loaded (turbo:load event)')
})
document.addEventListener('turbo:frame-load', (event) => {
  console.log('PACK active_admin.js loaded (turbo:frame-load event)')
})
