import { Controller } from 'stimulus'


const options = {
    activeClasses: 'active-tab tab-button',
    inactiveClasses: 'inactive-tab tab-button',
};

export default class extends Controller {
    static targets = [ "button" ]



    connect() {
        console.log("tab_nav_controller#connect()")
        // find the button with class "active" and show the corresponding tab
        const activeTabId = this.buttonTargets.find(x => x.classList.contains('active-tab')).dataset.tabId
        console.log(activeTabId)
        if (activeTabId) {
            // use classes instead of tab ids to be able to add multiple tabs with same id
            const tabs = Array.from(document.getElementsByClassName(activeTabId))
            tabs.forEach(tab => tab.classList.remove('hidden'))
        }
    }

    onClick(e) {
        e.preventDefault()

        const activeTabId = e.target.dataset.tabId
        const tabIds = this.buttonTargets.map(target => target.dataset.tabId)


        // hide all tabs
        tabIds.forEach(id => {
            // use classes instead of tab ids to be able to add multiple tabs with same id
            Array.from(document.getElementsByClassName(id)).forEach(tab => tab.classList.add('hidden'))
        })
        // show only current tab
        if (activeTabId) {
            const tabs = Array.from(document.getElementsByClassName(activeTabId))
            tabs.forEach(tab => tab.classList.remove('hidden'))
        }

        this.buttonTargets.forEach(btnTarget => {
            // reset style of the tab button
            btnTarget.classList.value = ''
            // apply style dependent on selected state
            if (btnTarget.dataset.tabId === activeTabId) {
                // this is the new selected tab
                options.activeClasses.split(' ').forEach(class_name => btnTarget.classList.add(class_name))
            } else {
                options.inactiveClasses.split(' ').forEach(class_name => btnTarget.classList.add(class_name))
            }
        })
    }
}