import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['isChecked', 'invisibleIfIsChecked']

  connect() {
    this.toggleInputVisibility(this.isCheckedTarget.checked ? 'none' : 'block')
  }

  isCheckedChanged(e) {
    this.toggleInputVisibility(this.isCheckedTarget.checked ? 'none' : 'block')
  }

  toggleInputVisibility(visibilityState) {
    if (this.hasInvisibleIfIsCheckedTarget) {
      // toggle visibility
      this.invisibleIfIsCheckedTargets.forEach((element) => {
        element.style.display = visibilityState
      })
    }
  }
}
